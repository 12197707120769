import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import CheckToken from './CheckToken';
import { format } from 'date-fns';
import SelectSingleDate from './SelectSingleDate';
import Spinner from './Spinner';
import { cashwinner, cashWinnerCount, checkCashStatus, downloadDataAirtime } from '../api/api';
import { ServiceNameDetails } from '../lib/ServicenameDetails';
import * as XLSX from 'xlsx';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import Menu from './Menu'


function CashWinner() {
    const [winnerLists, setWinnerLists] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [serviceCounts, setServiceCounts] = useState([]);
    const [serviceCountAllData, setServiceCountAllData] = useState([]);
    const [filterdata, setFilterData] = useState([]);
    const [expandedAccordion, setExpandedAccordion] = useState(false);


    const navigate = useNavigate();

    const [values, setValues] = useState({
        msisdn: '',
        DATE: null,
        service: ServiceNameDetails[0].name,
    });

    useEffect(() => {
        if (!CheckToken('emt')) {
            navigate('/');
        }
    }, [navigate]);

    const handleChange = (e) => {
        if (e?.target) {
            setValues({
                ...values,
                [e.target.name]: e.target.value,
            });
        } else {
            setValues({
                ...values,
                DATE: e,
            });
        }
    };

    const fetchCashWinner = async (msisdn, DATE) => {
        setLoading(true);
        setShowAlert(false);
        try {
            let formattedDate = '';
            if (DATE) {
                formattedDate = format(DATE, 'yyyy-MM-dd');
            }
            const response = await axios.get(`${cashwinner}?msisdn=${msisdn}&serviceName=${values.service}&DATE=${formattedDate}`);
            if (response.data) {
                setWinnerLists(response.data.message);
                setLoading(false);
            }
            if (response.data.message.length === 0) {
                setShowAlert(true);
            }
        } catch (error) {
            console.log(error);
            setShowAlert(true);
        } finally {
            setLoading(false);
        }
    };

    const fetchWinnerCount = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${cashWinnerCount}?serviceName=${values.service}`);
            if (response.data && response.data.message) {
                setServiceCounts(response.data.message);
                const allRecords = response.data.message.flatMap(item => item.records);
                setServiceCountAllData(allRecords);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetchCashWinner(values.msisdn, values.DATE);
    };


    const handleStatusChange = async (id, currentStatus, currentAccordianDataIndex) => {
        try {
            const newStatus = currentStatus === '1' ? '0' : '1';
            const response = await axios.post(checkCashStatus, {
                id: id,
                paid_status: newStatus
            });
            console.log('response:', response);
            if (response.status === 200) {
                console.log('Status updated successfully');
                setFilterData(prevState => {
                    const updatedData = [...prevState];
                    updatedData[currentAccordianDataIndex].paid_status = newStatus;
                    return updatedData;
                })

            }
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };


    useEffect(() => {
        fetchWinnerCount();
    }, []);

    useEffect(() => {
        fetchWinnerCount();
    }, [values.service]);

    const formateDate = (date) => {
        return format(new Date(date), 'dd-MM-yyyy');
    }

    const handleDownload = async (date) => {
        try {
            const response = await axios.get(`${downloadDataAirtime}?serviceName=${values.service}&DATE=${date}`);
            let downloadData = response.data.message.data;

            if (!Array.isArray(downloadData)) {
                downloadData = [downloadData];
            }

            const ws = XLSX.utils.json_to_sheet(downloadData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

            XLSX.writeFile(wb, `cash_winner_logs_${formateDate(date)}.xlsx`);
        } catch (error) {
            console.log(error);
        }
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
    };

    const filterDataByDate = (data, date) => {
        const filteredData = data.filter(item => {
            const itemDate = item.processDateTime.split('T')[0];
            return itemDate === date;
        });
        setFilterData(filteredData);
        console.log('data.filter(item => item.date === date): ', filteredData);
        return filteredData;
    };

    const handleAccordionChange = (index) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? index : false);
        if (isExpanded) {
            filterDataByDate(serviceCountAllData, serviceCounts[index].date);
        }
    };



    return (
        <div className='bg-black'>
            {/* <h1 className='sticky top-0 z-10 text-white bg-gradient-to-r from-cyan-500 to-blue-500 flex items-center text-2xl md:text-3xl lg:text-4xl font-sans font-semibold p-6 md:p-3 lg:p-5'>
                <BiArrowBack size={30} className="mr-2" onClick={() => navigate('/emt-service')} />
                <span className="flex-1 text-center font-serif">EMT REPORTING PORTAL</span>
            </h1> */}
             <div className='sticky top-0 z-50'>
            <Menu title={"EMT REPORTING PORTAL"} />
            </div>

            <h1 className='text-white flex justify-center items-center uppercase mt-6 underline text-2xl'>Select Details</h1>

            <form onSubmit={handleSubmit} className='flex flex-col lg:flex-row justify-center items-center gap-4 mt-8'>
                <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
                    <label className='text-white text-xl'>Enter Number: </label>
                    <input
                        type='text'
                        placeholder='Enter Number'
                        name='msisdn'
                        value={values.msisdn}
                        onChange={handleChange}
                        className='border lg:m-4 p-1 w-[180px] m-1 '
                    />
                </div>

                <div className="lg:flex items-center">
                    <div className="lg:flex lg:flex-row lg:items-center flex flex-col">
                        <label className='text-white text-xl'>Select Service:  </label>
                        <select name='service' value={values.service} onChange={handleChange} className='border p-1 m-1 w-[180px] lg:m-4'>
                            {ServiceNameDetails.map((service, i) => (
                                <option key={i} value={service.name}>
                                    {service.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <SelectSingleDate handleDateChange={handleChange} />
                    <button type="submit"
                        className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80
           font-medium rounded-lg text-sm px-5 py-2.5 text-center lg:mr-2 ml-12 lg:ml-8 mb-2 lg:mt-0 mt-12 md:mt-18">
                        Submit
                    </button>
                </div>
            </form>

            {loading && (
                <div className="text-center text-white h-screen">
                    <Spinner />
                </div>
            )}

            {!loading && (
                <>
                    <div className="mt-6 lg:mt-4 lg-32 flex justify-center items-center">
                        <div className="w-full lg:w-[900px] text-center m-4 lg:m-6">
                            <div className="relative overflow-x-auto shadow-lg rounded-lg">
                                <table className="w-full text-sm text-left text-blue-100 dark:text-blue-100 mt-4">
                                    <thead className="text-md text-white uppercase bg-gradient-to-r from-cyan-400 to-blue-600">
                                        <tr className='flex justify-between'>
                                            <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                                                Service
                                            </th>
                                            <th scope="col" className="px-4 sm:px-6 ml-20 md:px-6 py-4">
                                                Date
                                            </th>
                                            <th scope="col" className="px-4 sm:px-6 md:px-6 py-4">
                                                msisdn (count)
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {serviceCounts?.map((item, index) => (
                                            <tr key={index} className="bg-blue-100 text-black border-b border-blue-400">
                                                <td colSpan="4" className="px-4 sm:px-6 md:px-6 py-4">
                                                    <Accordion
                                                        // onChange={(event, isExpanded) => {
                                                        //     if (isExpanded) {
                                                        //         filterDataByDate(serviceCountAllData, item.date);
                                                        //         console.log(`Accordion opened for date: ${item.paid_status}`,index);
                                                        //     }
                                                        // }}
                                                        expanded={expandedAccordion === index}
                                                        onChange={handleAccordionChange(index)}
                                                    >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index}-content`}
                                                            id={`panel${index}-header`}
                                                        >
                                                            <div className="flex justify-between w-full">
                                                                <span>{values.service}</span>
                                                                <span>{item.date}</span>
                                                                <span>{item.count}</span>
                                                            </div>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <div>
                                                                <table className="min-w-full text-white" style={{ background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,100,121,1) 8%, rgba(0,212,255,1) 100%)' }}>                                                                    <thead>
                                                                    <tr>
                                                                        <th className="px-4 py-2">SERVICE NAME</th>
                                                                        <th className="px-4 py-2">AMOUNT</th>
                                                                        <th className="px-4 py-2">DATE</th>
                                                                        <th className="px-4 py-2">MSISDN</th>
                                                                        <th className="px-4 py-2">Unpaid/Paid</th>
                                                                    </tr>
                                                                </thead>
                                                                    <tbody>
                                                                        {filterdata?.map((data, i) => (
                                                                            <tr key={i} className="bg-blue-100 text-black border-b border-blue-400">
                                                                                <td className="px-4 py-2">{data.service}</td>
                                                                                <td className="px-4 py-2">{data.amount}</td>
                                                                                <td className="px-4 py-2">{item.date}</td>
                                                                                <td className="px-4 py-2">{data.msisdn}</td>
                                                                                <td className="px-4 py-2">
                                                                                    <Switch
                                                                                        inputProps={{ 'aria-label': 'Paid/Unpaid' }}
                                                                                        checked={data.paid_status === '1'}
                                                                                        onChange={() => handleStatusChange(data.id, data.paid_status, i)}
                                                                                    />
                                                                                </td>

                                                                            </tr>
                                                                        ))}
                                                                        {filterdata?.length === 0 && (
                                                                            <tr>
                                                                                <td colSpan="3" className="text-center font-bold py-4">No Data Available</td>
                                                                            </tr>
                                                                        )}
                                                                    </tbody>
                                                                </table>
                                                                <button
                                                                    className="mt-4 px-4 py-2 text-white bg-blue-600 hover:bg-blue-800 rounded"
                                                                    onClick={() => handleDownload(item.date)}
                                                                >
                                                                    Download Data
                                                                </button>
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className='lg:px-14 lg:mt-[-20px] md:px-8 md:mt-12 mt-3 px-1 pb-1 overflow-hidden'>
                        {winnerLists?.length > 0 && (
                            <table className='mx-auto mt-6 md:mt-4 lg:mt-16 lg:mb-12 w-full lg:w-full text-sm text-left text-blue-100 dark:text-blue-100 overflow-x-auto'>
                                <thead className='text-md text-white uppercase bg-gradient-to-r from-cyan-400 to-blue-600 dark:text-white'>
                                    <tr>
                                        <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                                            MSISDN
                                        </th>
                                        <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                                            service
                                        </th>
                                        <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                                            Amount
                                        </th>
                                        <th scope='col' className='px-4 sm:px-6 md:px-6 py-4'>
                                            Date
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {winnerLists?.map((item, i) => (
                                        <tr key={i} className='bg-blue-100 text-black border-b border-blue-400'>
                                            <td className='px-4 sm:px-6 md:px-6 py-4'>{item.msisdn}</td>
                                            <td className='px-4 sm:px-6 md:px-6 py-4'>{item.service}</td>
                                            <td className='px-4 sm:px-6 md:px-6 py-4'>{item.amount}</td>
                                            <td className='px-4 sm:px-6 md:px-6 py-4'>{formatDateTime(item.processDateTime)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>

                    {showAlert &&
                        <p className="text-center text-white mt-8 text-2xl lg:text-5xl font-sans">
                            Oops!<br />No data is available for the selected fields.
                        </p>
                    }
                </>
            )}
        </div>
    );
}

export default CashWinner;
