import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CheckToken from '../components/CheckToken';
import { recentMatchesApi, todayMatchesApi, upcomingMatchesApi } from '../api/api';
import Menu from './Menu';
import moment from "moment-timezone";

function GoalAlertMatches() {
    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [data, setData] = useState(null); 
    const [error, setError] = useState(null);
    const [category, setCategory] = useState("Recent Matches");
    const navigate = useNavigate();

    const fetchMatches = async (api, categoryName) => {
        try {
            setLoading(true);
            setCategory(categoryName);
            let timezone = moment.tz.guess();
            const response = await axios.post(api, {
                timezone: timezone,
            });
            let fetchedData = response.data?.data;
    
            // upcoming matches filter
            if (api === upcomingMatchesApi) {
                fetchedData = fetchedData.filter(
                    (match) => match?.fixture?.status?.long !== "Match Finished"
                );
            }
    
            setData(fetchedData);
        } catch (err) {
            console.error("Error in fetching data:", err);
            setError("Error in fetching data");
        } finally {
            setLoading(false);
        }
    };
    
    

    useEffect(() => {
        if (!CheckToken('emt')) {
            navigate('/');
        } else {
            fetchMatches(recentMatchesApi, "Recent Matches");
            
        }
    }, [navigate]);

   

    return (
        <div className='bg-black min-h-screen'>
            <div className='sticky top-0 z-50'>
                <Menu title={"EMT REPORTING PORTAL"} />
            </div>

            <div className='text-white text-xl max-[600px]:text-sm flex gap-4 justify-around pt-4 font-sans font-semibold'>
                <div
                    className='bg-gradient-to-r from-cyan-500 to-blue-500 p-2 cursor-pointer rounded-sm'
                    onClick={() => fetchMatches(recentMatchesApi, "Recent Matches")}
                >
                    Recent Matches
                </div>
                <div
                    className='bg-gradient-to-r from-cyan-500 to-blue-500 p-2 cursor-pointer rounded-sm'
                    onClick={() => fetchMatches(todayMatchesApi, "Today Matches")}
                >
                    Today Matches
                </div>
                <div
                    className='bg-gradient-to-r from-cyan-500 to-blue-500 p-2 cursor-pointer rounded-sm'
                    onClick={() => fetchMatches(upcomingMatchesApi, "Upcoming Matches")}
                >
                    Upcoming Matches
                </div>
            </div>

            <div className="p-2">
                <h2 className="text-2xl flex justify-center items-center font-bold text-white mb-2">
                    {category}
                </h2>
                {loading ? (
                    <p className="text-center text-white">Loading...</p>
                ) : data?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                        {data.map((match, index) => (
                            <div
                                key={index}
                                className="bg-white shadow rounded-lg p-4 flex flex-col items-center text-center"
                            >
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex flex-col items-center">
                                        <img src={match?.teams?.home?.logo} alt={""} className="w-12 h-12 mb-2" />
                                        <p className="font-semibold">{match?.teams?.home?.name}</p>
                                    </div>
                                    <p className="text-xl text-black font-bold">{match?.score?.fulltime?.home}</p>
                                    <p className="text-xl text-black font-bold">{match?.score?.fulltime?.away}</p>
                                    <div className="flex flex-col items-center">
                                        <img src={match?.teams?.away?.logo} alt={""} className="w-12 h-12 mb-2" />
                                        <p className="font-semibold">{match?.teams?.away?.name}</p>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-500 mt-2">
                                    {new Date(match?.fixture?.date).toDateString()} , {new Date(match?.fixture?.date).toLocaleTimeString()}
                                </p>
                                <div className='flex justify-center items-center pt-4'>
                                    <h2 className='pr-2 font-semibold'>Goal Alert Sent :</h2>
                                <button className=" bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                                    {/* {match?.result[0].total_sms ?? "No Data..."} */}
                                    {match?.result.length>0 ? match?.result[0]?.total_sms : "No Data..."}
                                    {/* {JSON.stringify(match?.result)} */}
                                </button>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-center text-white">No {category.toLowerCase()} found.</p>
                )}
            </div>
        </div>
    );
}

export default GoalAlertMatches;
