import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './components/HomePage';
import SubscriptionPage from './components/SubscriptionPage';
import MainPage from './components/MainPage';
import SignUp from './components/SignUp'
import AccessLogs from './components/AccessLogs';
import HomeInstantGlow from './components/HomeInstantGlow';
import GlowAccessLogs from './components/GlowAccessLogs';
import GlowCheckUser from './components/GlowCheckUser';
import SmsLogPage from './components/SmsLogPage';
import AirTime from './components/AirTime.js';
import CashWinner from './components/CashWinner.js';
import UserLogs from './components/UserLogs.js';
import GoalAlertMatches from './components/GoalAlertMatches.js';
function App() {
  return (
    <div className="bg-black h-screen">
      <Router>
        <Routes>
          <Route index element={<SignUp />} />
          <Route path="/glo" element={<HomeInstantGlow />} />
          <Route path="/glo/access-logs" element={<GlowAccessLogs />} />
          <Route path="/glo/check-logs" element={<GlowCheckUser />} />
          <Route path="/emt-service" element={<MainPage />} />
          <Route path="/emt-service/check-logs" element={<HomePage />} />
          <Route path="/emt-service/user-logs" element={<UserLogs />} />
          <Route path="/emt-service/sms-logs" element={<SmsLogPage />} />
          <Route path="/emt-service/airtime-winner" element={<AirTime />} />
          <Route path="/emt-service/cash-winner" element={<CashWinner />} />
          <Route path="/emt-service/subscription" element={<SubscriptionPage />} />
          <Route path="/emt-service/access-logs" element={<AccessLogs />} />
          <Route path="/emt-service/match-alert" element={<GoalAlertMatches />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
